.header-container{
    width: 100%;
    border-bottom: 1px solid rgb(74, 121, 3);
    display: flex;
    flex-direction: column;
    align-items: center;
}
.header-body{
    height: 60px;
    width: 80%;
    display: flex;
    padding-left: 50px;
    padding-right: 50px;
    justify-content: space-between;
    align-items: center;
}
.header-logo-div{
    height: 40px;
    width: fit-content;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.header-logo-name{
    font-size: 30px;
    color: rgb(74, 121, 3);
    font-weight: bolder;
    margin-left: 10px;
}
/* .logo-ring-1{
    height: 30px;
    width: 30px;
    border: 3px solid rgb(74, 121, 3);
    border-radius: 50%;
    background-color: rgba(0,0,0,0);
}
.logo-ring-2{
    height: 20px;
    width: 20px;
    border: 3px solid rgb(74, 121, 3);
    border-radius: 50%;
    background-color: rgba(0,0,0,0);
    position: relative;
    left: 5px;
    top: 5px;
}
.logo-ring-3{
    height: 10px;
    width: 10px;
    border: 3px solid rgb(74, 121, 3);
    border-radius: 50%;
    background-color: rgba(0,0,0,0);
} */

.header-logo-img{
    height: 40px;
    width: 40px;
    /* fill: rgb(74, 121, 3); */
}
.header-camera-icon{
    max-height: 40px;
    max-width: 40px;
    height: auto;
    width: auto;
}