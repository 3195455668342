.PagePostview-page-navigator-container {
    margin-top: 20px;
    height: 24px;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: flex-start;

}

.PagePostview-page-navigator-arrow-box-prev {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FFFFFF;
    box-shadow: inset 1px 1px 20px rgba(0, 0, 0, 0.1);
    border-radius: calc(5px * (var(--heightmultiplier) + var(--widthmultiplier))/2);
    margin-right: calc(10px * var(--widthmultiplier));
}
.PagePostview-page-navigator-arrow-box-prev:hover{
    color: #2DA5FC;
    cursor: pointer;
}

.PagePostview-page-navigator-arrow-box-prev2 {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FFFFFF;
    box-shadow: inset 1px 1px 20px rgba(0, 0, 0, 0.1);
    border-radius: calc(5px * (var(--heightmultiplier) + var(--widthmultiplier))/2);
    margin-right: calc(10px * var(--widthmultiplier));
}
.PagePostview-page-navigator-arrow-box-next {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FFFFFF;
    box-shadow: inset 1px 1px 20px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    margin-left: 10px;

}
.PagePostview-page-navigator-arrow-box-next:hover{
    cursor: pointer;
    color: #2DA5FC;
}
.PagePostview-page-navigator-arrow-box-next2 {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FFFFFF;
    box-shadow: inset 1px 1px 20px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    margin-left: 10px;

}


.PagePostview-page-navigator-arrow-img {
    width: 14px;
    height: 9px;
}


.PagePostview-page-navigator-num {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;

    color: #CBCBCB;
    margin-left: 11px;
    margin-right: 11px;
}
.PagePostview-page-navigator-num:hover{
    cursor: pointer;
}
.PagePostview-page-navigator-num-active{
    font-style: normal;
    font-weight: 600;
    font-size: 16px;

    color: #2DA5FC;
    margin-left: 11px;
    margin-right: 11px;
}